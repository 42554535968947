<template>
  <Top />
  <AboutTriage />
  <Facility />
  <Line />
  <Insurance />
  <OnlineInsurance />
  <CompanyOverview />
  <Access />
  <CeoProfile />
  <StaffIntroduction />
  <Line />
  <ContactForm />
</template>

<script>
import Top from "../components/Home/Top.vue";
import AboutTriage from "../components/Home/AboutTriage.vue";
import Facility from "../components/Home/Facility.vue";
import Line from "../components/Home/Line.vue";
import Insurance from "../components/Home/Insurance.vue";
import CompanyOverview from "../components/Home/CompanyOverview.vue";
import Access from "../components/Home/Access.vue";
import CeoProfile from "../components/Home/CeoProfile.vue";
import StaffIntroduction from "../components/Home/StaffIntroduction.vue";
import ContactForm from "../components/Home/ContactForm.vue";
import OnlineInsurance from "../components/Home/OnlineInsurance.vue";

export default {
  name: "HomeVue",
  components: {
    Top,
    AboutTriage,
    Facility,
    Line,
    Insurance,
    OnlineInsurance,
    CompanyOverview,
    Access,
    CeoProfile,
    StaffIntroduction,
    ContactForm,
  },
};
</script>

<style lang="scss" scoped></style>
